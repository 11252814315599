@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

ul {
  margin: 1rem 0 0 1rem;
  list-style-type:none;
}

.nav-title {
  font-family: 'Open Sans';
}
@media (min-width: 1100px) {
  
  .large-row {
    flex-direction: row;
  }
}